import React, { useState, createRef } from "react";
import { buildClasses } from "@nodeme/jss-react";
import classNames from "classnames";
const style = (theme) => (definitions) => {
    const defaults = definitions.nodeMeForms;
    return {
        formControl: defaults.formControl,
        label: defaults.label,
        inlineLabel: defaults.inlineLabel,
        closedLabel: defaults.closedLabel,
        inlineLabelOpen: defaults.inlineLabelOpen,
        inputHolder: defaults.inputHolder,
        input: defaults.input,
        inputReadOnly: defaults.inputReadOnly,
        textarea: defaults.textarea,
        inputError: defaults.inputError,
        helpBlock: defaults.helpBlock,
        hasError: defaults.hasError,
        suggestHolder: {
            position: "relative",
        },
        suggestList: {
            position: "absolute",
            padding: "0px",
            backgroundColor: "#fff",
            width: "calc(100%)",
            boxShadow: "0px 5px 8px 0px rgba(50, 50, 50, 0.75)",
            top: 0,
            margin: 0,
            listStyle: "none",
            maxHeight: "300px",
            overflowY: "scroll",
            zIndex: 1,
        },
        suggestItem: {
            padding: "8px 16px",
            "&:hover": {
                color: "#fff",
                backgroundColor: theme.palette.get("primary"),
                cursor: "pointer",
            },
        },
    };
};
const Text = (props) => {
    var _a;
    const { label, readOnly = false, open = false, inline = false, value, onChange, error, rows = false, type, info, autoComplete = "off", override = {}, } = props;
    const [isFocused, setFocuse] = useState(false);
    const classes = buildClasses(style);
    const textAreaRef = createRef();
    const inputRef = createRef();
    const hasInfo = Boolean(info);
    const hasError = Boolean(error);
    return (React.createElement("div", { className: classes.formControl, onClick: () => {
            if (props.onClick)
                props.onClick();
        } },
        (!props.value || !inline) && (React.createElement("label", { htmlFor: label, className: classNames([
                inline ? classes.inlineLabel : classes.label,
                !readOnly &&
                    !inline &&
                    !open &&
                    !isFocused &&
                    !Boolean(value) &&
                    classes.closedLabel,
                inline && Boolean(value) && classes.inlineLabelOpen,
                hasError && classes.hasError,
            ]), onClick: (e) => {
                if (!isFocused && textAreaRef && textAreaRef.current) {
                    textAreaRef.current.focus();
                }
                if (!isFocused && inputRef && inputRef.current) {
                    inputRef.current.focus();
                }
            } }, label)),
        React.createElement("div", { className: classNames([!inline && classes.inputHolder]) },
            Boolean(rows) && (React.createElement("textarea", { rows: rows || 1, className: classNames([
                    classes.textarea,
                    hasError && classes.inputError,
                    override.input,
                ]), name: label, ref: textAreaRef, value: value || "", onChange: (e) => {
                    if (readOnly || !onChange)
                        return;
                    else if (typeof e.target.value === "string" &&
                        e.target.value !== "")
                        onChange(e.target.value);
                    else
                        onChange();
                }, onFocus: (e) => setFocuse(true), onBlur: (e) => setFocuse(false) })),
            !Boolean(rows) && (React.createElement("input", { className: classNames([
                    classes.input,
                    readOnly && classes.inputReadOnly,
                    hasError && classes.inputError,
                    override.input,
                ]), name: label, ref: inputRef, value: readOnly && value === "" ? "-" : value || "", type: type || "text", autoComplete: autoComplete, onChange: (e) => {
                    if (readOnly || !onChange)
                        return;
                    else if (typeof e.target.value === "string" &&
                        e.target.value !== "")
                        onChange(e.target.value);
                    else
                        onChange();
                }, onFocus: (e) => {
                    if (props.onFocus)
                        props.onFocus();
                    setFocuse(true);
                }, onBlur: (e) => {
                    const target = e.relatedTarget;
                    if (!target || !target.classList.contains(classes.suggestItem)) {
                        if (props.onBlur)
                            props.onBlur();
                        setFocuse(false);
                    }
                } })),
            Array.isArray(props.suggest) && props.suggest.length > 0 && (React.createElement("div", { className: classes.suggestHolder },
                React.createElement("ul", { className: classNames([
                        classes.suggestList,
                        (_a = props.override) === null || _a === void 0 ? void 0 : _a.suggestList,
                    ]) }, props.suggest.map((item) => {
                    var _a;
                    return (React.createElement("li", { key: `input-suggest-${item}`, tabIndex: 0, className: classNames([
                            classes.suggestItem,
                            (_a = props.override) === null || _a === void 0 ? void 0 : _a.suggestItem,
                        ]), onClick: (e) => {
                            e.preventDefault();
                            if (props.onSuggestionSelect)
                                props.onSuggestionSelect(item);
                        } }, item));
                })))),
            hasInfo && (React.createElement("span", { className: classNames([classes.helpBlock]) }, info)),
            hasError && (React.createElement("span", { className: classNames([classes.helpBlock, classes.hasError]) }, error)))));
};
export default Text;
