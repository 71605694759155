import React from "react";
import { buildClasses } from "@nodeme/jss-react";
import classNames from "classnames";
const classStyle = (theme) => (definitions) => {
    const defaults = definitions.nodeMeForms;
    return {
        button: {
            ...defaults.button,
            width: (props) => props.width || "100%",
        },
        "button-primary": defaults["button-primary"],
        "button-warning": defaults["button-warning"],
        "button-success": defaults["button-success"],
        "button-danger": defaults["button-danger"],
        "button-disabled": defaults["button-disabled"],
    };
};
const Button = (props) => {
    const { children, primary, success, warning, danger, type, onClick = () => { }, style = {}, } = props;
    const variant = (primary && "primary") ||
        (success && "success") ||
        (warning && "warning") ||
        (danger && "danger") ||
        "default";
    const classes = buildClasses(classStyle, props);
    return (React.createElement("button", { disabled: props.disabled, className: classNames([
            classes.button,
            !props.disabled
                ? classes[`button-${variant}`]
                : classes["button-disabled"],
        ]), type: type, onClick: (e) => {
            if (!props.disabled)
                onClick(e);
            else
                e.preventDefault();
        }, style: style }, children));
};
export default Button;
